import { useDispatch } from "react-redux";
import { useRef } from "react";
import { addItemAsync, deleteALLAsync } from "../thunks";

export default function Panel() {
  const itemNameRef = useRef(null);
  const itemDescriptionRef = useRef(null);
  const itemPriceRef = useRef(null);
  const imageUploadRef = useRef(null);
  const sellerRef = useRef(null);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemName = itemNameRef.current.value;
    const itemDescription = itemDescriptionRef.current.value;
    const itemPrice = itemPriceRef.current.value;
    const imageUpload = imageUploadRef.current.value;
    const seller = sellerRef.current.value;

    const newItem = {
      name: itemName,
      description: itemDescription,
      price: itemPrice,
      imgUrl: imageUpload,
      seller: seller,
    };

    dispatch(addItemAsync(newItem));
  };

  const handleClear = (e) => {
    e.preventDefault();
    dispatch(deleteALLAsync());
  };

  const handleClearInputs = () => {
    itemNameRef.current.value = "";
    itemDescriptionRef.current.value = "";
    itemPriceRef.current.value = "";
    imageUploadRef.current.value = "";
    sellerRef.current.value = "";
  };

  return (
    <div id="Inventory">
      <form id="addItemForm" onSubmit={handleSubmit}>
        <h1>Inventory Management</h1>

        <input
          type="text"
          id="itemName"
          placeholder="Item name"
          required
          ref={itemNameRef}
        />
        <input
          type="text"
          id="itemDescription"
          placeholder="Item description"
          ref={itemDescriptionRef}
        />

        <input
          type="number"
          id="itemPrice"
          placeholder="Item price"
          required
          ref={itemPriceRef}
        />

        <input
          type="text"
          id="seller"
          placeholder="Seller"
          required
          ref={sellerRef}
        />
        <input
          type="text"
          id="imageUpload"
          placeholder="Image URL"
          name="imageUpload"
          ref={imageUploadRef}
        />

        <button type="submit">Add Item</button>
        <button onClick={handleClearInputs}>Clear Inputs</button>
        <button onClick={handleClear}>Clear List</button>
      </form>
    </div>
  );
}
