const initialState = {
  items: [],
};

const listOperation = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      const newItem = {
        ...action.payload,
        view: true,
      };
      return {
        items: [...state.items, newItem],
      };

    case "CLEAR_LIST":
      return {
        items: [],
      };

    case "DELETE_ITEM":
      const newItems = state.items.filter(
        (item) => item._id !== action.payload
      );
      return {
        items: newItems,
      };

    case "INFO":
      const updatedItems = state.items.map((item) => {
        if (item._id === action.payload) {
          return { ...item, view: !item.view };
        }

        return item;
      });

      return {
        ...state,
        items: updatedItems,
      };

    case "LOAD_DATA":
      const items = action.payload;
      const itemsWithView = items.map((item) => {
        return { ...item, view: true };
      });
      return {
        ...state,
        items: itemsWithView,
      };

    case "UPDATE_ITEM":
      const updated = state.items.map((item) => {
        if (item._id === action.payload[0]) {
          return { ...item, price: action.payload[1] };
        }

        return item;
      });

      return {
        items: updated,
      };

    default:
      return state;
  }
};

export default listOperation;
