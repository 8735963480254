import {
  addItem,
  clearList,
  deleteItem,
  fetchData,
  updatedItem,
} from "./actions";

export const getItemsAsync = () => {
  return async (dispatch) => {
    const res = await fetch("https://my-bookstore-server.onrender.com/items", {
      method: "GET",
    });
    const data = await res.json();
    dispatch(fetchData(data));
  };
};

export const addItemAsync = (item) => {
  return async (dispatch) => {
    const res = await fetch("https://my-bookstore-server.onrender.com/items", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await res.json();
    dispatch(addItem(data));
  };
};

export const deleteItemAsync = (id) => {
  return async (dispatch) => {
    const res = await fetch(
      `https://my-bookstore-server.onrender.com/items/${id}`,
      {
        method: "DELETE",
      }
    );
    dispatch(deleteItem(id));
  };
};

export const deleteALLAsync = () => {
  return async (dispatch) => {
    const res = await fetch(`https://my-bookstore-server.onrender.com/items`, {
      method: "DELETE",
    });
    dispatch(clearList());
  };
};

export const updateItemAsync = (id, itemPrice) => {
  return async (dispatch) => {
    const res = await fetch(
      `https://my-bookstore-server.onrender.com/items/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ price: itemPrice }),
      }
    );
    dispatch(updatedItem(id, itemPrice));
  };
};
