import NavBar from "../components/NavBar";
import Panel from "../components/Panel";
import InventoryList from "../components/InventoryList";

export default function Home() {
  return (
    <>
      <NavBar />
      <Panel />
      <InventoryList />
    </>
  );
}
