import { useDispatch } from "react-redux";
import { info } from "../actions";
import { deleteItemAsync } from "../thunks";
import EditPanel from "./EditPanel";

export default function ItemDetail({ name, description, price, _id, seller }) {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteItemAsync(_id));
  };

  const handleInfo = () => {
    dispatch(info(_id));
  };

  return (
    <section className="itemDetail">
      <h3>{name}</h3>
      <button onClick={handleInfo}>info</button>
      <button onClick={handleDelete}>delete</button>
      <p>{description}</p>
      <span>{"Seller: " + seller}</span>
      <span>{"  Price: $" + price}</span>
      <EditPanel id={_id} />
    </section>
  );
}
