export const addItem = (item) => {
  return {
    type: "ADD_ITEM",
    payload: item,
  };
};

export const clearList = () => {
  return {
    type: "CLEAR_LIST",
  };
};

export const deleteItem = (id) => {
  return {
    type: "DELETE_ITEM",
    payload: id,
  };
};

export const info = (id) => {
  return {
    type: "INFO",
    payload: id,
  };
};

export const fetchData = (data) => {
  return {
    type: "LOAD_DATA",
    payload: data,
  };
};

export const updatedItem = (id, price) => {
  return {
    type: "UPDATE_ITEM",
    payload: [id, price],
  };
};
