import { useSelector, useDispatch } from "react-redux";
import Item from "./Item";
import ItemDetail from "./ItemDetail";
import { getItemsAsync } from "../thunks";
import { useEffect } from "react";

export default function InventoryList() {
  const dispatch = useDispatch();
  const currList = useSelector((state) => state.listOperation);

  useEffect(() => {
    dispatch(getItemsAsync());
  }, []);

  return (
    <>
      <section className="inventorylist">
        {currList.items.map((item) => {
          if (item.view) {
            return <Item key={item._id} {...item} />;
          } else {
            return <ItemDetail key={item._id} {...item} />;
          }
        })}
      </section>
    </>
  );
}
