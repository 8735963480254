import { useDispatch } from "react-redux";
import { useRef } from "react";
import { updateItemAsync } from "../thunks";

export default function EditPanel({ id }) {
  const itemPriceRef = useRef(null);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemPrice = itemPriceRef.current.value;

    dispatch(updateItemAsync(id, itemPrice));
  };

  return (
    <form id="UpdateItemForm" onSubmit={handleSubmit}>
      <input
        type="number"
        id="itemPrice"
        placeholder="Item price"
        ref={itemPriceRef}
      />
      <button type="submit">Update</button>
    </form>
  );
}
